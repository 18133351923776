<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">ADI and Spec Grading Quiz</h2>

      <p class="mb-5">
        Which of the following describes the idea of a justification in scientific argumentation?
        Please select ALL correct options.
      </p>

      <p class="mb-2">The justification is...</p>

      <div class="checkbox-group pl-6 mb-0">
        <v-checkbox
          v-for="option in justificationMeaningOptions"
          :key="'pt-1-' + option.value"
          :value="option.value"
          class="mb-n4"
          v-model="inputs.justificationMeaning"
          :label="option.text"
          dense
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        justificationMeaning: [],
      },
      justificationMeaningOptions: [
        {text: 'the reasoning connecting the evidence to the data', value: 'a'},
        {text: 'uses scientific ideas connecting analyzed data to conclusions', value: 'b'}, // correct
        {text: 'is the relationship between variables', value: 'c'},
        {text: 'is the logical and explicit connection between claim & evidence', value: 'd'}, // correct
        {text: 'none of these', value: 'e'},
      ],
    };
  },
};
</script>
<style scoped></style>
